





























import { Prop, Watch } from 'vue-property-decorator'
import InputWithValidation from '@/components/inputs/address/InputWithValidation'
import { HouseBox, HouseNumberItem, HouseNumberAsset } from '@/models'
import Component from 'vue-class-component'

@Component
export default class HouseNumberAssetSelector extends InputWithValidation {
  @Prop({ type: Number, required: true }) public readonly streetSubCityId!: number
  @Prop(Object) public value!: HouseNumberAsset | null

  protected houseNumberItems: HouseNumberItem[] = []
  protected selectedHouseNumberData: HouseNumberItem | null = null
  protected selectedHouseBox: HouseBox | null = null
  protected houseBoxData: string | null = null
  protected listIndex: number = 0
  protected houseBoxes: HouseBox[] = []

  protected get houseNumbers(): HouseNumberItem[] {
    return this.houseNumberItems
              .sort((a, b) => parseInt(a.houseNumber, 10) - parseInt(b.houseNumber, 10))
  }

  protected get selectedHouseNumber(): HouseNumberItem | null {
    return this.selectedHouseNumberData
  }

  protected get hasItems(): boolean {
    return !!this.houseNumberItems && !!this.houseNumberItems.length
  }

  protected get isHouseBoxRequired(): boolean {
    return !!this.houseBoxes && !!this.houseBoxes.length
  }

  @Watch('streetSubCityId')
  public async onStreetSubCityIdChanged(): Promise<void> {
    this.$nextTick(async () => {
      await this.fetchHouseNumbers()
    })
  }

  public async mounted() {
    if (this.streetSubCityId !== null) {
      await this.fetchHouseNumbers()
      this.setHouseNumber(this.houseNumbers.find((hn) => hn.houseNumber === this.value?.houseNumber) ?? null)
    }
  }

  protected getHouseBoxText(val: HouseBox | null): string {
    return val?.complement
        ?? val?.complementBox
        ?? val?.floorNumber
        ?? ''
  }

  protected setHouseNumber(val: HouseNumberItem | string | null) {
    if (typeof val === 'string' || val instanceof String) {
      this.selectedHouseNumberData = {
        houseNumber: val.toString(),
        streetSubcityId: null,
        houseBoxes: null
      }
      this.houseBoxes = []
    } else {
      this.selectedHouseNumberData = Object.assign({}, val)
      this.houseBoxes = val?.houseBoxes?.slice(0) ?? []
    }
    this.$emit('input', {houseNumber: this.selectedHouseNumberData?.houseNumber, houseBox: this.selectedHouseBox})
  }

  protected setHouseBox(val: HouseBox | string | null) {
    if (typeof val === 'string' || val instanceof String) {
      this.selectedHouseBox = {
        complement : val.toString(),
        complementBox : null,
        floorNumber : null
      }
    } else {
      this.selectedHouseBox = val
    }

    this.$emit('input', {houseNumber: this.selectedHouseNumber?.houseNumber, houseBox: this.selectedHouseBox})
  }

  private async fetchHouseNumbers(): Promise<void> {
    const streetSubCityId = this.streetSubCityId
    this.houseNumberItems = []

    if (streetSubCityId === null || isNaN(streetSubCityId) || streetSubCityId === -1) {
      if (this.streetSubCityId === -1) {
        this.setHouseNumber(this.houseNumbers.find((hn) => hn.houseNumber === this.value?.houseNumber) ?? null)
      } else {
        this.setHouseNumber(null)
      }
      return
    }

    await this.$api
      .post('api/eanrequest/HouseNumbers', streetSubCityId, { headers: { 'Content-Type': 'application/json' }})
      .then((response) => { this.houseNumberItems = response.data });
  }
}
