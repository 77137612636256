











import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import Situation3a from './situation3a.vue';
import Situation3b from './situation3b.vue';

@Component({
  components: {
    Situation3a,
    Situation3b
  },
})
export default class Situation3 extends Vue {
  @State('isRetry', { namespace: 'eanRequest/contact' })
  public readonly isRetry!: boolean;

  protected onFirstNameChanged(value: string | null): void {
    this.$emit('firstNameChanged', value);
  }

  protected onLastNameChanged(value: string | null): void {
    this.$emit('lastNameChanged', value);
  }

  protected onPhoneNumberChanged(value: string | null): void {
    this.$emit('phoneNumberChanged', value);
  }

  protected onGetEanByMailConfirmed(value: boolean): void {
    this.$emit('getEanByMailConfirmed', value);
  }
}
