













import { SubCity } from '@/models';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

interface Styles {
  style: 'green' | 'orange';
  logo: string;
  messages: string[];
}

enum Logo {
  Both = 'icon-both',
  Elec = 'icon-elec',
  Gas = 'icon-gaz',
  Up = 'fas fa-thumbs-up',
  Down = 'fas fa-thumbs-down'
}

@Component
export default class ConnectionPointArea extends Vue {
  //#region [Props]
  @Prop(Object) public readonly subCity!: SubCity | null;
  @Prop(Boolean) public readonly checkForGas?: boolean;
  @Prop(Boolean) public readonly checkForElec?: boolean;
  @Prop(Boolean) public readonly hideSuccessMessages?: boolean;
  @Prop(Boolean) public readonly showSynergridLink?: boolean;
  @Prop({ type: Boolean, default: false, required: false }) public readonly hideOresManagerMessage?: boolean;
  @Prop({ type: String, required: false }) public readonly baseResourcePath?: string | null;
  //#endregion

  //#region [Data]
  public loading: boolean = false;
  public areaInformation: SubCity | null = null;
  //#endregion

  //#region [Computed]
  get energyManagerMessages(): Styles[] {
    // [ { style : « orange/green », logo : « icon-elec/icon-gaz/icon-both », messages : [ …, …] }
    if (!this.checkForElec && !this.checkForGas) {
      return [];
    }
    const info = this.areaInformation;
    if (!info) {
      return [];
    }

    const allowGas = (info.gridManagers.gasManager && info.gridManagers.gasManager.isOres) ?? false;
    const allowElec = (info.gridManagers.electricityManager && info.gridManagers.electricityManager.isOres) ?? false;

    this.$emit('canAllowGas', allowGas);
    this.$emit('canAllowElec', allowElec);
    this.$emit('canAllowElecOrGas', allowGas || allowElec);

    // 1. operator?
    if (!info.gridOperators || !info.gridOperators.isInOresScope) {
      if (this.checkForElec && this.checkForGas) {
        return [this.getStyles(false, Logo.Down, 'oresElecGasNotManager')];
      } else if (this.checkForElec) {
        // check for elec only
        return [this.getStyles(false, Logo.Down, 'oresElecNotManager')];
      } else {
        // check for gas only
        return [this.getStyles(false, Logo.Down, 'oresGasNotManager')];
      }
    }

    const isGasManager = info.gridManagers.gasManager && info.gridManagers.gasManager.isOres;
    const isElecManager = info.gridManagers.electricityManager && info.gridManagers.electricityManager.isOres;

    // ORES is operator
    if (this.checkForElec) {
      // 2. electricity manager ?
      if (isElecManager) {
        if (this.checkForGas) {
          // 3. gas manager ?
          if (isGasManager) {
            if (!this.hideSuccessMessages) {
              return [this.getStyles(true, Logo.Up, 'oresElecGasManager')];
            }
          } else {
            // ores not gas manager
            if (this.hideSuccessMessages) {
              return [this.getStyles(false, Logo.Down, 'oresGasNotManager')];
            } else {
              return [
                this.getStyles(true, Logo.Up, 'oresElecManager'),
                this.getStyles(false, Logo.Down, 'oresGasNotManager')
              ];
            }
          }
        } else {
          // don't check for gas
          if (!this.hideSuccessMessages) {
            return [this.getStyles(true, Logo.Up, 'oresElecManager')];
          }
        }
      } else {
        // ores not elec manager
        if (this.checkForGas) {
          // 3. gas manager ?
          if (isGasManager) {
            if (this.hideSuccessMessages) {
              return [this.getStyles(false, Logo.Down, 'oresElecNotManager')];
            } else {
              return [
                this.getStyles(false, Logo.Down, 'oresElecNotManager'),
                this.getStyles(true, Logo.Up, 'oresGasManager')
              ];
            }
          } else {
            // not gas manager
            return [this.getStyles(false, Logo.Down, 'oresElecGasNotManager')];
          }
        } else {
          return [this.getStyles(false, Logo.Down, 'oresElecNotManager')];
        }
      }
    } else {
      // no check for elec
      if (this.checkForGas) {
        // 3. gas manager ?
        if (isGasManager) {
          if (!this.hideSuccessMessages) {
            return [this.getStyles(true, Logo.Up, 'oresGasManager')];
          }
        } else {
          // ores not gas manager
          return [this.getStyles(false, Logo.Down, 'oresGasNotManager')];
        }
      }
    }

    return [];
  }

  private get basePath(): string {
    const path = this.baseResourcePath ?? 'common.oresNetwork';
    return path.endsWith('.') ? path : `${path}.`;
  }
  //#endregion

  //#region [Watch]
  @Watch('subCity')
  public onSubCityChanged(subCity: SubCity | null) {
    this.$nextTick(() => {
      this.fetchAreaInformation();
    });
  }
  //#endregion

  //#region [Methods]
  public mounted(): void {
    if (this.subCity !== null) {
      this.fetchAreaInformation();
    }
  }

  public fetchAreaInformation(): void {
    if (this.subCity === null) {
      this.areaInformation = null;
      return;
    }

    this.areaInformation = this.subCity;
  }

  private getMessageKey(key: string): string[] {
    const path = this.basePath + key;
    return [path];
  }

  private getStyles(isGreen: boolean, logo: Logo, messageKey: string): Styles {
    const style = isGreen ? 'green' : 'orange';
    const messages = this.getMessageKey(messageKey);
    return { style, logo, messages };
  }
  //#endregion
}
