





































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NoMobile extends Vue {
  @Prop({ required: true, type: Boolean })
  public value!: boolean
  @Prop({ required: false, type: String })
  public address!: string

  protected dontHaveMobile: boolean = false

  protected get isChecked(): boolean {
    return this.value
  }

  protected set isChecked(val: boolean) {
    this.$emit('input', val)
  }

  protected get hasAddress(): boolean {
    return !!this.address
  }
}
