



























import BaseStep from './BaseStep'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { EanRequestAddress, RequestAddressModel, SubCity } from '@/models'
import { Mutation } from 'vuex-class'
import RequestAddress from '@/components/inputs/ean-request/address/RequestAddress.vue'
import ConnectionPointArea from '@/components/inputs/address/ConnectionPointArea.vue'
import VerifyMeter from '@/components/inputs/ean-request/address/VerifyMeter.vue'

@Component({
  components: {
    RequestAddress,
    ConnectionPointArea,
    VerifyMeter,
  },
})
export default class AddressStep extends BaseStep {
  @Prop({ default: true, type: Boolean })
  public isActive!: boolean

  protected requestAddressModel: RequestAddressModel | null = null
  protected meterLastFourNumbers: string | null = null
  protected isOresManager: boolean = false
  protected isCaptcha: boolean = true

  @Mutation('STORE_CAPTCHA_TOKEN', { namespace: 'eanRequest' })
  protected storeCaptchaToken!: (token: string) => void
  @Mutation('STORE', { namespace: 'eanRequest/address' })
  protected store!: (index: EanRequestAddress) => void
  @Mutation('SET_RETRY', { namespace: 'eanRequest/contact' })
  protected setRetry!: () => void

  public get isMeterZoneVisible(): boolean {
    return (
      this.requestAddressModel != null &&
      this.requestAddressModel.streetSubCity.georesStreetSubcityId > 0 &&
      !!this.requestAddressModel.houseNumberAsset &&
      !!this.requestAddressModel.houseNumberAsset.houseNumber &&
      this.isOresManager
    )
  }

  protected get isCaptchaVisible(): boolean {
    return this.isMeterZoneVisible && this.isCaptcha
  }

  protected get subCity(): SubCity | null {
    return this.requestAddressModel?.subCity ?? null
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (!next) {
      this.setRetry()
    } else if (isValid && this.isOresManager) {
      const data = this.createAddress()
      if (data !== null) {
        this.store(data)
        return true
      }
    }
  }

  @Watch('isOresManager')
  public isOresManagerChanged(value: boolean): void {
    this.$emit('isOresManagerChanged', value)
  }

  @Watch('isActive')
  public onIsActiveChanged(isActive: boolean): void {
    // reload captcha when back to this step
    this.isCaptcha = isActive
  }

  @Watch('isMeterZoneVisible')
  public resetMeterLastFourNumbers(isVisible: boolean): void {
    if (!isVisible) {
      this.meterLastFourNumbers = null
    }
  }

  protected canAllowElecOrGasCallback(canAllowElecOrGas: boolean): void {
    this.isOresManager = canAllowElecOrGas
  }

  private createAddress(): EanRequestAddress | null {
    if (this.requestAddressModel == null || this.meterLastFourNumbers == null) {
      return null
    }

    return Object.assign({}, this.requestAddressModel, { meterSerialNumberLastFourDigits: this.meterLastFourNumbers })
  }
}
