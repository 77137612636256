


















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class Situation3a extends Vue {
  @Getter('formattedAddress', { namespace: 'eanRequest/address' })
  public readonly formattedAddress!: string;
  @Getter('meterLastDigits', { namespace: 'eanRequest/address' })
  public readonly meterLastDigits!: string | null;
}
