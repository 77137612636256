













































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class ConfirmationFeedback extends Vue {
  @Prop ({ required: true, type: Number })
  public context!: 0 | 1 | 2 | 3 | 4;
  @Prop ({ required: true })
  public mobilePhoneNumber!: string | null;
  @Getter('formattedAddress', { namespace: 'eanRequest/address' })
  public readonly formattedAddress!: string | null;
}
