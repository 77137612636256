

















































import { Component, Vue } from 'vue-property-decorator'
import { State, Getter } from 'vuex-class'
import { EanRequestAddress } from '@/models'
import NoMobile from '@/components/inputs/ean-request/NoMobile.vue'
import Tooltip from '@/components/inputs/ean-request/tooltip.vue'

@Component({
  components: {
    NoMobile,
    Tooltip,
  },
})
export default class Situation3b extends Vue {
  @State('address', { namespace: 'eanRequest/address' })
  public addressState!: EanRequestAddress
  @Getter('formattedAddress', { namespace: 'eanRequest/address' })
  public readonly formattedAddress!: string

  protected firstNameData: string | null = null
  protected lastNameData: string | null = null
  protected phoneNumberData: string | null = null
  protected isGetEanByMailConfirmedData: boolean = false

  protected get firstName(): string | null {
    return this.firstNameData
  }

  protected set firstName(val: string | null) {
    this.firstNameData = val
    this.$emit('firstNameChanged', val)
  }

  protected get lastName(): string | null {
    return this.lastNameData
  }

  protected set lastName(val: string | null) {
    this.lastNameData = val
    this.$emit('lastNameChanged', val)
  }

  protected get phoneNumber(): string | null {
    return this.phoneNumberData
  }

  protected set phoneNumber(val: string | null) {
    this.phoneNumberData = val
    this.$emit('phoneNumberChanged', val)
  }

  protected get isGetEanByMailConfirmed(): boolean {
    return this.isGetEanByMailConfirmedData
  }

  protected set isGetEanByMailConfirmed(val: boolean) {
    this.isGetEanByMailConfirmedData = val
    this.$emit('getEanByMailConfirmed', val)
  }
}
