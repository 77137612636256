






































import { Component, Vue, Prop } from 'vue-property-decorator'
import Tooltip from '@/components/inputs/ean-request/tooltip.vue'
import { State } from 'vuex-class';

@Component({
  components: {
    Tooltip,
  },
})
export default class VerifyMeter extends Vue {
  @Prop({ required: true })
  public value!: string | null

  @State('lang')
  public lang!: string | null;

  protected isFindMeterLastDigitsOpen: boolean = false

  protected get meterLastFourNumbers(): string | null {
    return this.value
  }

  protected set meterLastFourNumbers(val: string | null) {
    if (val === null || val.length > 4 || (!!val.length && Number.isNaN(val))) {
      return
    }

    this.$emit('input', val)
  }
}
