



















import BaseStep from './BaseStep'
import { Component } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'
import { ContactInputState } from '@/store/ean-request/contact/types'
import Situation1 from '@/components/inputs/ean-request/contact/situation1.vue'
import Situation2 from '@/components/inputs/ean-request/contact/situation2.vue'
import Situation3 from '@/components/inputs/ean-request/contact/situation3.vue'

@Component({
  components: {
    Situation1,
    Situation2,
    Situation3
  }
})
export default class ContactStep extends BaseStep {
  public maskedPhoneNumber: string | null = null

  @State('isEanFound', { namespace: 'eanRequest/contact' })
  protected readonly isEanFoundState!: boolean
  protected firstName: string | null = null
  protected lastName: string | null = null
  protected phoneNumber: string | null = null
  protected isPhoneNumberFound: boolean = false
  protected hasResultData: boolean = false
  protected isGetEanByMailConfirmed: boolean = false

  @Mutation('STORE_INPUT', { namespace: 'eanRequest/contact' })
  protected storeContactInput!: (data: ContactInputState) => void
  @Mutation('STORE_ISEANFOUND', { namespace: 'eanRequest/contact' })
  protected storeEanFound!: (isFound: boolean) => void
  @Mutation('SET_RETRY', { namespace: 'eanRequest/contact' })
  protected setRetry!: () => void
  @Mutation('STORE_BEARER', { namespace: 'eanRequest' })
  protected storeBearer!: (data: string) => void

  @Action('setApiError', { namespace: 'api-errors' })
  protected setApiError!: () => Promise<void>

  public get hasResult(): boolean {
    return this.hasResultData
  }

  public set hasResult(val: boolean) {
    this.hasResultData = val
    this.$emit('hasResult', val)
  }

  public get isEanFound(): boolean {
    return this.isEanFoundState
  }

  public set isEanFound(isFound: boolean) {
    this.storeEanFound(isFound)
  }

  public get situation(): number {
    if (this.isEanFound) {
      if (this.isPhoneNumberFound) {
        return 1
      } else {
        return 2
      }
    } else {
      return 3
    }
  }

  public async mounted() {
    const data = {
      subcityId: this.addressState.subCity.georesSubcityId,
      streetSubcityId: this.addressState.streetSubCity?.georesStreetSubcityId,
      houseNumber: this.addressState.houseNumberAsset.houseNumber,
      houseBox: this.addressState.houseNumberAsset.houseBox,
      meterSerialNumberLastFourDigits: this.addressState.meterSerialNumberLastFourDigits
    }

    const response = await this.$api
      .post('api/eanrequest/GetPhoneNumberForAddress', data, this.httpHeaders)
      .then((r) => r.data)

    if (!response) {
      await this.setApiError()
    } else {
      this.storeBearer(response.bearer)
      if (response.isFound) {
        this.maskedPhoneNumber = response.maskedMobilePhoneNumber
        this.isEanFound = true
        this.hasResult = true
        this.isPhoneNumberFound = !!response.maskedMobilePhoneNumber
      } else {
        this.isEanFound = false
        this.hasResult = true
      }
    }

    this.$emit('situationChanged', this.situation)
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (!next) {
      this.setRetry()
    }

    if (next && isValid) {
      const sendByMail = this.isGetEanByMailConfirmed
      const mobilePhoneNumber = !sendByMail ? this.phoneNumber : null

      this.storeContactInput({
        sendByMail,
        mobilePhoneNumber,
        firstName: this.firstName,
        lastName: this.lastName
      })
    }
  }

  public onFirstNameChanged(value: string | null): void {
    this.firstName = value
  }

  public onLastNameChanged(value: string | null): void {
    this.lastName = value
  }

  public onPhoneNumberChanged(value: string | null): void {
    this.phoneNumber = value
  }

  public onGetEanByMailConfirmed(value: boolean): void {
    this.isGetEanByMailConfirmed = value
  }
}
