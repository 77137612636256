import Step from '@/components/steps/Step'
import { Getter, Mutation, State } from 'vuex-class'
import { EanRequestAddress } from '@/models'

export default abstract class BaseStep extends Step {
  @State('requestId', { namespace: 'eanRequest' })
  protected readonly requestId!: string

  @Getter('httpHeaders', { namespace: 'eanRequest' })
  protected readonly httpHeaders!: any
  @Getter('eanRequestAddress', { namespace: 'eanRequest/address' })
  protected readonly addressState!: EanRequestAddress
}
