









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Tooltip extends Vue {
  @Prop({ required: true, type: String })
  public message!: string;
}
