





























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { SubCity, StreetSubCity, RequestAddressModel, HouseNumberAsset } from '@/models';
import ZipCodeLocalitySelector from '@/components/inputs/address/ZipCodeLocalitySelector.vue'
import StreetSubCitySelector from '@/components/inputs/address/StreetSubCitySelector.vue'
import HouseNumberSelector from '@/components/inputs/address/HouseNumberSelector.vue'
import HouseNumberAssetSelector from './HouseNumberAssetSelector.vue'

@Component({
  components: {
    ZipCodeLocalitySelector,
    StreetSubCitySelector,
    HouseNumberSelector,
    HouseNumberAssetSelector
  },
})
export default class RequestAddress extends Vue {
  @Prop ({ required: true })
  public value!: RequestAddressModel | null;

  protected subCityData: SubCity | null = null;
  protected streetSubCityData: StreetSubCity | null = null;
  protected houseNumberData: HouseNumberAsset | null = null;

  protected get subCity(): SubCity | null {
    return this.subCityData
  }

  protected set subCity(val: SubCity | null) {
    this.subCityData = val
    this.streetSubCity = null
    this.houseNumber = null
  }

  protected get streetSubCity(): StreetSubCity | null {
    return this.streetSubCityData
  }

  protected set streetSubCity(val: StreetSubCity | null) {
    this.streetSubCityData = val
    this.houseNumber = null
  }

  protected get houseNumber(): HouseNumberAsset | null {
    return this.houseNumberData
  }

  protected set houseNumber(val: HouseNumberAsset | null) {
    this.houseNumberData = val
    this.emitUpdateValue()
  }

  protected get isSubCityValid(): boolean {
    return this.subCity != null && this.subCity.georesSubcityId > 0
  }

  protected get isStreetSubCityValid(): boolean {
    return this.streetSubCity != null && this.streetSubCity.georesStreetId > 0
  }

  private get requestAddressModel(): RequestAddressModel | null {
    if (this.subCity == null || this.streetSubCity == null || this.houseNumber == null) {
      return null
    }
    return {
      subCity: this.subCity,
      streetSubCity: this.streetSubCity,
      houseNumberAsset: this.houseNumber
    }
  }

  @Watch('subCity')
  public onSubCityChanged(val: SubCity | null): void {
    if (!!val) {
      this.scrollToBottom()
    }
  }

  @Emit()
  protected reset(): void {
    this.subCity = null
  }

  private emitUpdateValue(): void {
    this.$emit('input', this.requestAddressModel)
  }

  private scrollToBottom(): void {
    this.$el.scrollIntoView({block: 'end', behavior: 'smooth'})
  }
}
