




import BaseStep from './BaseStep'
import { Component } from 'vue-property-decorator'
import { Getter, Mutation, State } from 'vuex-class'
import { SendEanRequest } from '@/models'
import ConfirmationFeedback from '@/components/inputs/ean-request/confirmation/ConfirmationFeedback.vue'

@Component({
  components: {
    ConfirmationFeedback
  }
})
export default class ConfirmationStep extends BaseStep {
  @State('isEanFound', { namespace: 'eanRequest/contact' })
  protected readonly isEanFoundState!: boolean

  @Getter('mobilePhoneNumber', { namespace: 'eanRequest/contact' })
  protected readonly mobilePhoneNumberState!: string
  @Getter('firstName', { namespace: 'eanRequest/contact' })
  protected readonly firstNameState!: string | null
  @Getter('lastName', { namespace: 'eanRequest/contact' })
  protected readonly lastNameState!: string | null
  @Getter('sendByMail', { namespace: 'eanRequest/contact' })
  protected readonly sendByMailState!: boolean
  @Getter('hasPhoneNumber', { namespace: 'eanRequest/contact' })
  protected readonly hasPhoneNumber!: boolean;

  @Mutation('CLEAR_BEARER', { namespace: 'eanRequest' })
  protected clearBearer!: () => void

  protected isSmsSent: boolean = false
  protected mobilePhoneNumber: string | null = null
  protected isMailSent: boolean = false
  protected isLocked: boolean = false
  protected hasError: boolean = false

  protected get confirmationContext(): number {
    if (this.isLocked) {
      return 4
    }

    if (this.isSmsSent) {
      return 1
    }

    if (this.isMailSent) {
      return this.isEanFoundState ? 2 : 3
    }

    return 0
  }

  public async mounted() {
    if (this.sendByMailState) {
      await this.sendEanNotification()
    } else {
      const isLocked = await this.isPhoneLocked()
      this.isLocked = isLocked
      if (!isLocked && !this.hasError) {
        await this.sendEanNotification()
      }
    }

    this.$appInsights.trackEvent('EanRequest_ConfirmationStep', {
      Segment: 'EanRequest',
      WebRef: this.requestId,
      isEanFound: `${this.isEanFoundState}`,
      isSms: `${this.isSmsSent}`,
      isMail: `${this.isMailSent}`,
      isLocked: `${this.isLocked}`,
      hasPhoneNumber: `${this.hasPhoneNumber}`
    })

    this.clearBearer();
  }

  private async isPhoneLocked(): Promise<boolean> {
    const data: any = {
      subcityId : this.addressState.subCity.georesSubcityId,
      streetSubcityId : this.addressState.streetSubCity?.georesStreetSubcityId,
      houseNumber : this.addressState.houseNumberAsset,
      meterSerialNumberLastFourDigits : this.addressState.meterSerialNumberLastFourDigits
    }

    if (!!this.mobilePhoneNumberState) {
      data.MobilePhoneNumber = this.mobilePhoneNumberState
    }

    return await this.$api
      .post('api/eanrequest/GetPhoneLockStatus', data, this.httpHeaders)
      .then((response) => response.status === 200 && response.data.isLocked)
      .catch((reason) => {
        this.hasError = true
        return false
      })
  }

  private async sendEanNotification(): Promise<void> {
    const postData = this.createSendEanData()
    if (postData === null) {
      throw TypeError('SendEanData is null')
    }

    const responseData = await this.$api
      .post('api/eanrequest/SendEanCodesNotification', postData, this.httpHeaders)
      .then((response) => response.data)
      .catch((reason) => reason.response.data)

    if (responseData != null) {
      this.isSmsSent = responseData.isSmsSent
      this.isMailSent = responseData.isMailSent
      this.isLocked = responseData.isLocked
      this.mobilePhoneNumber = responseData.mobilePhoneNumber
    }
  }

  private createSendEanData(): SendEanRequest | null {
    const addr = this.addressState
    if (addr?.subCity == null || addr?.streetSubCity == null || addr?.houseNumberAsset == null) {
      return null
    }
    return {
      zipCode: addr.subCity.zipCode,
      subcityId: addr.subCity.georesSubcityId,
      streetSubcityId: addr.streetSubCity.georesStreetSubcityId,
      houseNumber: addr.houseNumberAsset,
      meterSerialNumberLastFourDigits: addr.meterSerialNumberLastFourDigits,
      firstName: this.firstNameState,
      lastName: this.lastNameState,
      mobilePhoneNumber: this.sendByMailState ? null : this.mobilePhoneNumberState,
      sendByMail: this.sendByMailState
    }
  }
}
