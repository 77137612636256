






































import { Form } from '@/views/forms/Form'
import { Component, Watch } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'
import { StepDefinition, ApiErrorNotification } from '@/models'
import Step from '@/components/steps/Step'
import ApiErrors from '@/components/display/ApiErrors.vue'
import StepActions from '@/components/shared/StepActions.vue'
import AddressStep from '@/components/steps/eanrequest/AddressStep.vue'
import ContactStep from '@/components/steps/eanrequest/ContactStep.vue'
import ConfirmationStep from '@/components/steps/eanrequest/ConfirmationStep.vue'

@Component({
  name: 'eanrequest',
  components: {
    ApiErrors,
    StepActions,
    AddressStep,
    ContactStep,
    ConfirmationStep,
  },
})
export default class EanRequest extends Form {
  //#region [Data]
  @State('apiErrors', { namespace: 'apiErrors' })
  public readonly apiErrors!: ApiErrorNotification | null
  @State('isRetry', { namespace: 'eanRequest/contact' })
  public readonly isRetry!: boolean

  public isWarningModalOpen: boolean = true
  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('identification', 1),
    new StepDefinition('contact', 2),
    new StepDefinition('confirmation', 3),
  ] as StepDefinition[]

  public contactSituation: number = 0
  public isOresManager: boolean = false
  public hasResult: boolean = false
  //#endregion

  //#region [Method]
  @Action('reset', { namespace: 'eanRequest' })
  public resetState!: () => Promise<void>
  @Mutation('SET_RETRY', { namespace: 'eanRequest/contact' })
  public setRetry!: () => void

  public getStepComponent(step: number): Step | null {
    switch (step) {
      case 1:
        return this.$refs.step1 as Step
      case 2:
        return this.$refs.step2 as Step
      case 3:
        return this.$refs.step3 as Step
      default:
        return null
    }
  }

  public async goToNext() {
    if (this.contactSituation === 3 && !this.isRetry) {
      this.setRetry()
    } else {
      await this.tryNextStep()
    }
  }

  public onWarningMessageUnderstood(): void {
    this.isWarningModalOpen = false
    this.currentStep = 1
  }

  public mounted() {
    this.onLangChanged(this.lang)
  }

  public beforeCreate() {
    document.title = this.$t('eanRequest.formTitle').toString()
  }

  public created() {
    this.CheckMaintenance('ean-request');
  }
  //#endregion

  //#region [Computed]
  public get showStepAction(): boolean {
    return (this.currentStep === 1 && this.isOresManager) || (this.currentStep === 2 && this.hasResult)
  }

  public get nextLabel() {
    switch (this.currentStep) {
      case 1:
        return this.$t('eanRequest.buttons.search').toString()
      case 2:
        if (this.contactSituation === 3 && !this.isRetry) {
          return this.$t('eanRequest.buttons.confirm').toString()
        }
        return this.$t('eanRequest.buttons.send').toString()
      default:
        return null
    }
  }

  public get previousLabel() {
    return this.$t('eanRequest.buttons.edit').toString()
  }

  public get hasPrevious() {
    return this.currentStep === 2
  }
  //#endregion

  //#region [Watch]
  @Watch('lang')
  public Reset() {
    this.resetState()
    this.isWarningModalOpen = true
    this.currentStep = 0
    this.lastStepSeen = 1
  }
  //#endregion
}
