import { render, staticRenderFns } from "./ZipCodeLocalitySelector.vue?vue&type=template&id=13f13fa5&scoped=true"
import script from "./ZipCodeLocalitySelector.vue?vue&type=script&lang=ts"
export * from "./ZipCodeLocalitySelector.vue?vue&type=script&lang=ts"
import style0 from "./ZipCodeLocalitySelector.vue?vue&type=style&index=0&id=13f13fa5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f13fa5",
  null
  
)

export default component.exports