
























































import { Component, Vue, Prop } from 'vue-property-decorator'
import Tooltip from '@/components/inputs/ean-request/tooltip.vue'
import { chatService } from '@/services'

@Component({
  components: {
    Tooltip,
  },
})
export default class Situation1 extends Vue {
  @Prop({ required: true, type: String })
  public phoneNumber!: string

  public isNotMyNumber: boolean = false
  protected newPhoneNumberData: string | null = null

  public get newPhoneNumber(): string | null {
    return this.newPhoneNumberData
  }

  public set newPhoneNumber(data: string | null) {
    this.newPhoneNumberData = data
    this.$emit('phoneNumberChanged', data)
  }

  protected openChat() {
    chatService.openChat()
  }
}
